<template>
  <div uk-height-viewport class="uk-flex uk-flex-middle">
    <div class="uk-width-3-3@m uk-width-1-3@s m-auto rounded">
      <div class="uk-child-width-2-2@m uk-grid-collapse" uk-grid>
        <div class="uk-card-default p-5 rounded">
          <div class="mb-4">
            <h3 class="mb-3">{{ $t("login.create_password_title") }}</h3>
            <p>{{ $t("login.craete_password") }}</p>
          </div>
          <form @submit.prevent="onSubmit()">
            <div class="uk-form-group">
              <label class="uk-form-label"> {{ $t("general.email") }} </label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-mail"></i>
                </span>
                <input
                  class="uk-input"
                  type="email"
                  v-model="form.email"
                  required
                />
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">
                {{ $t("general.password") }}
              </label>
              <div class="uk-position-relative w-100 position-relative">
                <span class="uk-form-icon">
                  <i class="icon-feather-lock"></i>
                </span>
                <input
                  class="uk-input"
                  :type="passwordType ? 'text' : 'password'"
                  v-model="form.password"
                  required
                />
                <i
                  class="icon-feather-eye open-password"
                  @click="openPassword('passwordType')" 
                ></i>
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">
                {{ $t("general.password_confirm") }}
              </label>
              <div class="uk-position-relative w-100 position-relative">
                <span class="uk-form-icon">
                  <i class="icon-feather-lock"></i>
                </span>
                <input
                  class="uk-input"
                  :type="confirmPasswordType ? 'text' : 'password'"
                  v-model="form.password_confirmation"
                  required
                />
                <i
                  class="icon-feather-eye open-password"
                  @click="openPassword('confirmPasswordType')"
                ></i>
              </div>
            </div>

            <div class="uk-alert-danger" uk-alert v-if="errors">
              <a class="uk-alert-close" uk-close></a>
              <p style="color: red">
                <i class="uil-exclamation-triangle"></i>{{ $t(errors) }}
              </p>
            </div>

            <div class="uk-alert-success" uk-alert v-if="message">
              <a class="uk-alert-close" uk-close></a>
              <p style="color: green">
                <i class="uil-exclamation-triangle"></i> {{ message }}
              </p>
            </div>

            <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
              <div class="uk-width-auto@s">
                <button
                  type="button"
                  class="btn btn-warning mr-2"
                  @click="$router.push({ name: 'login' })"
                >
                  {{ $t("general.back") }}
                </button>
                <button type="submit" class="btn btn-default">
                  {{ $t("forgot_password.send") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { CREATE_PASSWORD, SET_ERROR } from "@/core/services/store/auth.module";

export default {
  name: "CreatePassword",
  data() {
    return {
      form: {
        email: null,
        password: null,
        password_confirmation: null,
        token: null,
        alias: null,
      },
      confirmPasswordType: false,
      passwordType: false,
      message: null,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    openPassword(element) {
      switch (element) {
        case "passwordType":
          this.passwordType = !this[element];
          break;
        case "confirmPasswordType":
          this.confirmPasswordType = !this[element];
          break;
      }
    },
    onSubmit() {
      let form = this.form;

      form.alias = process.env.VUE_APP_DEFAULT_SUBDOMAIN;

      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        form.alias = window.location.hostname.split(".")[0];
      }

      if (this.form.password !== this.form.password_confirmation) {
        let errorMessage = this.$t("errors.password_dont_match");
        this.$store.commit(SET_ERROR, errorMessage);
        return false;
      }
      // send forgot password request
      this.$store.dispatch(CREATE_PASSWORD, form).then((response) => {
        this.message = this.$t("messages.reset_password_successfully");
        setTimeout(() => this.$router.push({ name: "login" }), 1000);
      });
    },
  },
  mounted() {
    this.$store.commit(SET_ERROR, "");
    let attributes = this.$route.fullPath.split("&");
    let token = "";
    attributes.forEach((item) => {
      let attribute = item.split("=");
      if (attribute[0] === "token") {
        token = attribute[1];
      }
    });
    if (token) {
      this.form.token = token;
    }
    if (!this.form.token) {
      this.$router.push({ name: "login"})
    }
  },
};
</script>

<style scoped>
.open-password {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.open-password:hover {
  cursor: pointer;
}
</style>
